$(function(){
    var margin = 0;
    var speed = 1000;

    $('a[href^=#]').click(function() {
        var hash = this.hash;

        if( hash ){
            //console.log('href hash : '+hash);
            var target = $(hash);
            var position = target.offset().top;
            position -= margin;
            $('html, body').animate({scrollTop:position}, speed, 'swing');
        }
    });

    $(window).load(function(){
        var hash = window.location.hash;

        if( hash ){
            //console.log('location hash : '+hash);
            var target = $(hash);
            var position = target.offset().top;
            position -= margin;
            $('html, body').animate({scrollTop:position}, speed, 'swing');
        }
    });
});